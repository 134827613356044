import { apiChat } from '@api/chat';
import { mergeArrayById } from '@helpers/tools';
import { useFetch } from '@helpers/use-fetch';
import { ChatMessage } from '@models/chat';
import { PRIVATE_TYPE } from '@models/menu';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toast-notification';

export const useChatStore = defineStore('chat', () => {
  const chatsApi = useFetch(apiChat.getChats);
  const chatApi = useFetch(apiChat.getChat);
  const chatMessagesApi = useFetch(apiChat.getChatMessages);
  const createChatMessageApi = useFetch(apiChat.createChatMessage);
  const markAllMessagesReadApi = useFetch(apiChat.markAllMessagesRead);

  const toast = useToast();

  const chatId = ref<number | null>(null);
  const patientId = ref<number | null>(null);
  const providerId = ref<number | null>(null);
  const chatMessages = ref<ChatMessage[]>([]);
  const chatMessagesPage = ref<number>(0);
  const triggerBottomScroll = ref<number>(0);

  const chats = computed(() => chatsApi.response.value?.data || []);
  const chat = computed(() => chatApi.response.value?.data || []);
  const chatMessagesData = computed(() => chatMessagesApi.response.value?.data || []);
  const chatMessagesPagination = computed(() => ({
    totalCount: 0,
    defaultPageSize: 0,
    ...chatMessagesApi.response.value?.pages,
  }));

  const chatMessagesParsed = computed(() => {
    return chatMessages.value.map((item) => {
      const linkRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
      const match = item.message.match(linkRegex);

      return match
        ? {
          ...item,
          message: item.message.replaceAll(linkRegex, `<a href="${match[0]}" target="_blank" ref="noopener noreferrer nofollow">${match[0]}</a>`),
        }
        : item;
    });
  });

  const chatMessagesSorted = computed(() => chatMessagesParsed.value.sort((a, b) => b.id - a.id));
  const createChatMessageLoading = computed(() => createChatMessageApi.loading.value);

  const getChatMessages = (next: boolean) => {
    if (!chatId.value) {
      return;
    }

    chatMessagesApi.execute({
      chatId: chatId.value,
      page: chatMessagesPage.value,
    }).then(() => {
      if (next) {
        // Each time a page is successfully received, we set the following page number
        chatMessagesPage.value += 1;
      }
    });
  };

  // Selecting the first chat when receiving chats
  watch(chats, () => {
    if (!chatId.value && chats.value.length) {
      chatId.value = chats.value[0].id;
    }
  });

  // Fetching the selected chat
  watch(chatId, () => {
    if (!chatId.value) {
      return;
    }

    chatMessages.value = [];
    chatMessagesPage.value = 0;

    getChatMessages(false);
  }, { immediate: true });

  // Replacing the url when changing the chat
  watch(chatId, () => {
    if (!chatId.value || !patientId.value) {
      return;
    }

    const isProvider = window.privateType === PRIVATE_TYPE.PROVIDER;
    const pathname = isProvider ? `/provider/messages/${patientId.value}/chat/${chatId.value}` : `/patient/chat/${chatId.value}`;

    history.replaceState({}, '', pathname);
  }, { immediate: true });

  // Adding new messages to the list
  watch(chatMessagesData, () => {
    chatMessages.value = mergeArrayById(chatMessages.value, chatMessagesData.value);
  });

  const setPatientId = (value: number) => {
    patientId.value = value;
  };

  const setProviderId = (value: number) => {
    providerId.value = value;
  };

  const getChats = async (payload: { chatId?: number }) => {
    if (payload?.chatId) {
      chatId.value = payload.chatId;
    }

    if (!patientId.value) {
      return;
    }

    await chatsApi.execute({ patientId: patientId.value });
  };

  const getChat = async (chatId: number) => {
    await chatApi.execute(chatId);
  };

  // Getting old messages
  const getMoreChatMessages = () => {
    const { totalCount, defaultPageSize } = chatMessagesPagination.value;
    const totalPages = Math.ceil(totalCount / defaultPageSize);

    if (chatMessagesPage.value < totalPages) {
      getChatMessages(true);
    }
  };

  // Sending a new message
  const createChatMessage = async (message: string, attachments: File[]) => {
    if (!chatId.value) {
      toast.warning('Chat is not selected');
      return;
    }

    if (!message && !attachments.length) {
      toast.warning('You cannot send an empty message');
      return;
    }

    await createChatMessageApi.execute({
      chatId: chatId.value,
      message,
      attachments,
    });

    await chatMessagesApi.execute({ chatId: chatId.value });

    // It is needed to trigger the transition to the sent message in the chat window
    triggerBottomScroll.value = new Date().getTime();
  };

  // Mark all messages as read
  const markAllMessagesRead = async () => {
    if (!chatId.value) {
      toast.warning('Chat is not selected');
      return;
    }

    const response = await markAllMessagesReadApi.execute(chatId.value);
    toast.success(response?.message || '');
  };

  return {
    setPatientId,
    setProviderId,
    patientId,
    providerId,
    chats,
    chat,
    chatMessagesPagination,
    chatMessagesPage,
    chatMessagesApi,
    createChatMessageApi,
    chatsApi,
    chatId,
    chatMessages,
    chatMessagesSorted,
    triggerBottomScroll,
    getChats,
    getChat,
    createChatMessage,
    getMoreChatMessages,
    markAllMessagesReadApi,
    markAllMessagesRead,
    getChatMessages,
    createChatMessageLoading,
  };
});
