import { PRODUCT_TYPE, PRODUCT_DISPLAY_TYPE } from '@models/product';
import { Variant } from '@models/variant';
import { kebabCase } from 'lodash';

export enum QUIZ_TYPE {
  QUIZ_WEIGHT_LOSS = 'quizWeightLoss',
  QUIZ_ERECTILE_DYSFUNCTION = 'quizErectileDysfunction',
  QUIZ_EARLY_CLIMAX = 'quizEarlyClimax',
  QUIZ_LIBIDO = 'quizLibido',
  QUIZ_HAIRCARE = 'quizHaircare'
}

export enum STEP_TYPE {
  AUTH = 'stepAuth',
  BASIC = 'stepBasic',
  SUMMARY = 'stepSummary',
  PHARMACY = 'stepPharmacy',
  CALENDLY = 'stepCalendly'
}

export enum STEP_CONTENT_TYPE {
  SIMPLE_CONTENT = 'simpleContent',
  COLUMNS_CONTENT = 'columnsContent',
  USP_CONTENT = 'uspContent',
  GOALS_CONTENT = 'goalsContent',
  SUMMARY_CONTENT = 'summaryContent',
  STARTER_CONTENT = 'starterContent',
  CART_CONTENT = 'cartContent',
  TWO_COLUMNS = 'twoAnswersGroupBodyContent',
  ANIMATION = 'animationContent'
}

export enum STEP_TRANSITION_TYPE {
  TIMEOUT = 'timeout',
  CONTINUE_ONLY = 'continueOnly',
  UNDERSTAND = 'understand',
  CONTINUE = 'continue',
  ANSWER = 'answer',
  SKIP = 'skip',
  ABORT = 'abort',
  FINISH = 'finish',
  CONTINUE_TIMEOUT = 'continueTimeout'
}

export enum STEP_ANSWER_CONTENT_TYPE {
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  RADIO_USES = 'radioUses',
  RADIO_SHIPMENTS = 'radioShipments',
  INPUT = 'input',
  MASK_INPUT = 'maskInput',
  STATES = 'states',
  COMMERCE_PLAN = 'commercePlan',
  COMMERCE_PRODUCT = 'commerceProduct',
  FILE_INPUT = 'fileInput',
  ALLERGIES = 'allergies'
}

export enum INPUT_FIELD_TYPE {
  INPUT = 'input',
  TEXTAREA = 'textarea',
  DATE = 'date'
}

export enum MASK_INPUT_FIELD_TYPE {
  TEXT = 'text',
  NUMBER = 'number',
  NAME = 'name',
  HEIGHT = 'height'
}

export enum QUIZ_STATUS {
  IN_PROGRESS = 'in progress',
  COMPLETED = 'completed'
}

export enum FILE_TYPE {
  DOCUMENT = 'document',
  PHOTO = 'photo',
  INSURANCE_FRONT = 'insuranceCardFront',
  INSURANCE_BACK = 'insuranceCardBack'
}

export enum CHECK_POSITION {
  LEFT = 'left',
  RIGHT = 'right'
}

export enum ANSWER_TAG {
  NONE = 'none',
  CONCERN = 'concern',
  TERMINATE = 'terminate'
}

export enum MODIFIER {
  NONE = 'none',
  QUANTITY_PER_MONTH = 'quantityPerMonth',
  PERIOD_MONTH = 'periodMonth',
  DOSAGE = 'dosage',
  USAGE = 'usage'
}

export interface Step {
  id: number
  title: string
  slug: string
  type: STEP_TYPE
  content: StepContent[]
  required: StepRequired[][]
  answers: StepAnswer<any>[]
  columns?: string
  background?: string
  transition?: STEP_TRANSITION_TYPE
  link?: string
}

export interface StepContent {
  type: STEP_CONTENT_TYPE,
  content: StepContentBody
}

export interface StepRequired {
  id: number
  type: STEP_TYPE.BASIC
}

export interface StepAnswer<T> {
  id: number
  title: string
  slug: string
  content: StepAnswerContent<T>[]
  exclusive: boolean
  answerTag: ANSWER_TAG
  terminateMessage: string | null
  value?: string
  modifier: MODIFIER,
  modifierValue: string
  modifierApplyTo: number[]
}

export interface StepAnswerContent<T> {
  type: STEP_ANSWER_CONTENT_TYPE
  content: T
}

export interface AnswerPayload {
  quizType: QUIZ_TYPE
  quizId: number
  status: QUIZ_STATUS
  content: AnswerMap | null
  lastStepId: number | null
  aid?: string | null
  treatmentId?: number | null
}

export interface TrackViewQuizStepPayload {
  stepSlug: string
  stepName?: string
  eventId: string
}

export interface TrackSelectQuizAnswerPayload {
  stepValue: string[]
  stepSlug: string
  eventId: string
}

export type AnswerMap = { [stepId: string]: StepAnswer<any>[] }

export interface StepContentBody {
  header?: string
  subheader?: string
  text?: string
  centered?: boolean
  left?: string
  right?: string
  person?: string
  lost?: string
  photo?: string
  quote?: string
  goal?: number[]
  bmi?: number[]
  achievement?: number[]
  mostGain?: number[]
  features?: Array<{
    image: string
    header: string
    description: string
  }>
  plan?: number[]
  addOns?: number[]
  medication?: number[]
  metabolicTest?: number[]
  usage?: number[]
  usesPerMonth?: number[]
  dosage?: number[]
  interval?: number[]
  picture?: string
  leftSideTitle?: string
  rightSideTitle?: string
  groupedByColumns?: boolean
  subtitle?: string[]
  answers1?: number[]
  answers2?: number[]
  answers3?: number[]
  answers4?: number[]
  iconTextList?: { icon: string, text: string }[]
}

export interface CommerceProduct {
  product: {
    id: number
    shippingAmount: number
    variants: Variant[]
  }
  variants: Variant[]
  image?: string
  displayType: PRODUCT_DISPLAY_TYPE
  contentColumns?: string
  label?: string
  description?: string
  dosage?: string
  startsWorkingIn?: string
  lastsUpTo?: string
  additional: boolean
  price?: string
  simple_price?: { [key: string]: number }
  min_price?: number
  url?: string
  clarification?: string
  underheading?: string
  product_type?: PRODUCT_TYPE
}

export interface CommercePlan {
  description?: string
  planItems?: Array<{
    item: string
    active: boolean
  }>
  label?: string
  price?: string
}

export interface Choice {
  label?: string
  picture?: string
}

export interface Input {
  placeholder?: string
  fieldType?: INPUT_FIELD_TYPE
  required?: string
}

export interface MaskInput {
  mask?: string
  fieldType?: MASK_INPUT_FIELD_TYPE
}

export interface FileInput {
  fileType?: FILE_TYPE
  mainDescription?: string
  reviewDescription?: string
}

export interface StateSelect {
  label?: string
  states: Option[]
}

export interface Option {
  label: string
  value: string
}

export interface AllergySelect {
  items: AllergyItem[]
  endpoint: string
  placeholder?: string
}

export interface PharmacySelect {
  items: PharmacyItem[]
  states: Option[]
  specialities: Option[]
  placeholder: string | null
  skip: boolean
}

export interface Checkbox {
  position: CHECK_POSITION
  required: boolean
  label?: string
  tip?: string
}

export interface Radio {
  position: CHECK_POSITION
  label?: string
  tip?: string
}

export interface RadioUses {
  position: CHECK_POSITION
  product: number[]
  label?: string
}

export interface RadioShipments {
  position: CHECK_POSITION
  product: number[]
  radioUsesAnswer: number[]
  radioUsesTypeAnswer: number[]
  label?: string
}

export type CheckElement = Checkbox & Radio & RadioUses & RadioShipments

export interface GetQuizResponse {
  submissionId: number | null
  quizId: number
  quizContent: Step[]
  lastStepId: number | null
  submission?: AnswerMap
  prefill?: { [stepId: string]: StepAnswer<any> }
  status: QUIZ_STATUS
  paid: boolean
  isRenewal: boolean
}

export interface AbortQuizResponse {
  success: boolean
}

export interface SetAnswersResponse {
  submissionId: number | null
  quizId: number
  quizContent: Step[]
  lastStepId: number | null
  submission?: AnswerMap
  prefill?: { [stepId: string]: StepAnswer<any> }
}

export interface AllergyItem {
  Name: string
  Code: string
  CodeType: number
  RxCUI: number
}

export type GetAllergiesResponse = AllergyItem[]

export interface PharmacyItem {
  Address1: string
  Address2: string
  City: string
  Latitude: number
  Longitude: number
  PharmacyId: number
  PharmacySpecialties: string[]
  PhoneAdditional1: string
  PhoneAdditional2: string
  PhoneAdditional3: string
  PhoneAdditionalType1: number
  PhoneAdditionalType2: number
  PhoneAdditionalType3: number
  PrimaryFax: string
  PrimaryPhone: string
  PrimaryPhoneType: number
  ServiceLevel: number
  State: string
  StoreName: string
  ZipCode: string
}

export type GetPharmaciesResponse = PharmacyItem[]

export interface QuizCategory {
  id: number
  title: string
  slug: string
  handle: string
  quizzes: { id: number, title: string, handle: string }[]
}

export const getQuizUrl = (handle: string): string|null => {
  if(!handle) {
    return null;
  }

  return `/quiz/${kebabCase(handle.replace('quiz', '').replace('Hmr', ''))}`;
};
