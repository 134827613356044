import { request } from '@helpers/fetch';
import {
  Chat,
  ChatMessage,
  GetChatMessagesPayload,
  CreateChatMessagePayload,
  ChatsPayload, AssignProviderChatPayload,
} from '@models/chat';
import { Response } from '@models/response';

export const apiChat = {
  getChats(payload: ChatsPayload): Promise<Response<Chat[]>> {
    return request.get('/api/v1/chats', payload);
  },
  getChatMessages(payload: GetChatMessagesPayload): Promise<Response<ChatMessage[]>> {
    return request.get('/api/v1/chats/messages', payload);
  },
  getChat(chatId: number): Promise<Response<[]>> {
    return request.get(`/api/v1/chats/${chatId}`);
  },
  assignProvider(payload: AssignProviderChatPayload): Promise<Response<[]>> {
    return request.put(`/api/v1/chats/${payload.chatId}/assign-provider`, payload);
  },
  createChatMessage(payload: CreateChatMessagePayload): Promise<Response<ChatMessage>> {
    const data = new FormData();
    for (const key in payload.attachments) {
      data.set(`attachments[${key}]`, payload.attachments[key]);
    }

    data.set('chatId', String(payload.chatId));
    data.set('message', payload.message);
    return request.postForm('/api/v1/chats/create-message', data);
  },
  markAllMessagesRead(chatId: number): Promise<Response<null>> {
    return request.post('/api/v1/chats/mark-all-messages-read', { chatId });
  },
};
